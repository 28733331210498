<template>
    <div>
        <el-card style="min-height: 45rem">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form label-position="top">
                        <el-form-item label="注册协议">
                            <y_editor eid="login_agree" v-model="form.login_agree"></y_editor>
                        </el-form-item>
                        <el-form-item label="用户协议">
                            <y_editor eid="member_agree" v-model="form.member_agree"></y_editor>
                        </el-form-item>
                        <el-form-item label="隐私条款">
                            <y_editor eid="privacy_agree" v-model="form.privacy_agree"></y_editor>
                        </el-form-item>
                      <el-form-item label="首页分享图片">
                        <y_upload_img v-model="form.index_share_pic"></y_upload_img>
                      </el-form-item>
                      <el-form-item label="首页分享标题">
                        <el-input v-model="form.index_share_title"></el-input>
                      </el-form-item>
                      <el-form-item label="首页分享描述">
                        <el-input v-model="form.index_share_desc"></el-input>
                      </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="12">
                    <el-form>
                      <el-form-item label="实名认证">
                        <el-switch v-model="form.ac_enable"></el-switch>
                        <div class="y-desc">开启后未实名用户申请提现时拦截认证</div>
                      </el-form-item>
                      <el-form-item v-if="form.ac_enable" label="腾讯云api-SecretID">
                        <el-input v-model="form.ac_secret_id"></el-input>
                      </el-form-item>
                      <el-form-item v-if="form.ac_enable" label="腾讯云api-SecretKey">
                        <el-input v-model="form.ac_secret_key"></el-input>
                      </el-form-item>
                        <el-form-item label="强制邀请码">
                            <el-switch v-model="form.must_invite_code"></el-switch>
                        </el-form-item>
                      <el-form-item label="杰猫salt">
                        <el-input v-model="form.jiemao_salt"></el-input>
                      </el-form-item>
                      <el-form-item label="杰猫礼包金额">
                        <el-input-number v-model="form.jiemao_gift_amount"></el-input-number>
                      </el-form-item>
                      <el-form-item label="杰猫代理提交用户类型">
                        <el-input v-model="form.jiemao_user_type"></el-input>
                      </el-form-item>
                      <el-form-item label="杰猫新注册用户类型">
                        <el-input v-model="form.jiemao_new_user_type"></el-input>
                      </el-form-item>
                      <el-form-item label="关闭系统支付">
                        <el-switch v-model="form.sys_pay_close"></el-switch>
                      </el-form-item>
                      <el-form-item label="开启汇聚支付">
                        <el-switch v-model="form.join_pay"></el-switch>
                      </el-form-item>
                      <el-form-item label="无支付选项位置启用汇聚支付">
                        <el-switch v-model="form.join_pay_plugin"></el-switch>
                      </el-form-item>
                        <el-form-item label="首页公众号绑定、关注引导">
                            <el-switch v-model="form.home_wechat_official_tips"></el-switch>
                        </el-form-item>
                        <el-form-item label="海报跳转域名">
                          <el-input v-model="form.poster_domain_jump"></el-input>
                          <div style="color: red" class="y-desc">注：开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
                        </el-form-item>
                        <el-form-item label="海报落地域名">
                          <el-input v-model="form.poster_domain_landing"></el-input>
                          <div style="color: red" class="y-desc">注：开头加http://或https://结尾不加 ‘/’例：http://abc.com</div>
                        </el-form-item>
                        <el-form-item label="海报文案">
                          <el-input v-model="form.poster_copy_writing" type="textarea" autosize></el-input>
                          <div class="y-desc">每行一条，回车换行</div>
                        </el-form-item>
                        <el-form-item label="腾讯地图key">
                            <el-input v-model="form.tx_map_key"></el-input>
                        </el-form-item>
                        <el-form-item label="商品采集接口API KEY">
                            <el-input v-model="form.import_goods_api_key"></el-input>
                        </el-form-item>
                        <el-form-item label="物流查询接口用户ID">
                            <el-input v-model="form.express_appid"></el-input>
                        </el-form-item>
                        <el-form-item label="物流查询接口API key">
                            <el-input v-model="form.express_app_key"></el-input>
                        </el-form-item>
                        <el-form-item label="客服二维码">
                            <y_upload_img v-model="form.waiter_qrcode"></y_upload_img>
                        </el-form-item>
                        <el-form-item label="升级代理文案">
                          <el-input v-model="form.up_agent_copy_writing"></el-input>
                        </el-form-item>
                        <el-form-item label="升级老板文案">
                          <el-input v-model="form.up_boss_copy_writing"></el-input>
                        </el-form-item>
                        <el-form-item align="right">
                            <el-button @click="edit" type="primary">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_editor from "@/components/y-form/y-editor/y_editor";

export default {
    name: "info",
    components: {
        y_upload_img,
        y_editor,
    },
    data () {
        return {
            form: {
                import_goods_api_key: "",

                express_appid: "",
                express_app_key: "",

                must_invite_code: false,
                waiter_qrcode: "",
                login_agree: "",
                member_agree: "",
                privacy_agree: "",

                index_share_title: "",
                index_share_desc: "",
                index_share_pic: "",

                tx_map_key: "",
                home_wechat_official_tips: false,

                poster_domain_jump:"",
                poster_domain_landing:"",

                poster_copy_writing:"",

                up_agent_copy_writing:"",
                up_boss_copy_writing:"",


              sys_pay_close:false,
              join_pay:false,
              join_pay_plugin:false,

              ac_enable:false,
              ac_secret_id:"",
              ac_secret_key:"",
            }
        }
    },
    mounted () {
        this.load();
    },
    methods: {
        load () {
            this.$u.api.shop.sys.info().then( res => {
                this.form = res;
            } )
        },
        edit () {
            this.$u.api.shop.sys.infoEdit( this.form ).then( () => {
                this.$message.success( "操作成功" );
                this.load();
            } )
        },
    }
}
</script>

<style scoped>

</style>